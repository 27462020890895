import './landing.css';
import React from 'react';
import { Link } from 'react-router-dom';
import family from '../../assets/family.jpg';

export default function Landing() {
    return (
        <div className="landing-page" style={{ backgroundImage: `url(${family})` }}>
            <div className="prompt">
                <h1>Protect what matters</h1>
                <p>Get a free quote for home or auto insurance so you can protect the things that matter most.</p>
                <div className="links">
                    <Link to="/landing/auto">
                        <button>Auto Insurance</button>
                    </Link>
                    <Link to="/landing/home">
                        <button>Home Insurance</button>
                    </Link>
                </div>
            </div>
            <a href="https://www.freepik.com/free-photo/happy-family-silhouette-sunset_8380524.htm#query=family&position=1&from_view=search&track=sph&uuid=e5a79155-8ef1-4e86-a397-0455d1d22ddb">Image by senivpetro on Freepik</a>
        </div>
    )
}
