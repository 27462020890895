import React from 'react';
import { Link } from 'react-router-dom';
import home from '../../assets/home.jpg';

export default function LandingHome() {
    return (
        <div className="landing-page" style={{ backgroundImage: `url(${home})` }}>
            <div className="prompt">
                <h1>Home Insurance</h1>
                <p>As your largest investment, it's important to make sure your home has the proper insurance coverage. We'll protect your home and everything else in it for the lowest possible price.</p>
                <div>
                    <Link to="/info?type=home">
                        <button>Get a Free Quote</button>
                    </Link>
                </div>
            </div>
            <a href="https://www.freepik.com/free-psd/modern-farmhouse-meadow-hill-generative-ai_47896217.htm#page=3&query=home&position=23&from_view=search&track=sph&uuid=9bcb2ec0-475e-4624-ba01-d70dc1d82aa8">Image by WangXiNa on Freepik</a>
        </div>
    )
}
