import "./info.css";
import React, { useMemo, useState } from 'react';
// @ts-ignore
import * as confetti from 'canvas-confetti';
import { useSearchParams } from 'react-router-dom';

const coerceType = (type: string): string => {
    return type === 'auto' || type === 'home' ? type : '';
}

export default function Info() {

    let [ firstName, setFirstName ] = useState('');
    let [ lastName, setLastName ] = useState('');
    let [ phoneNumber, setPhoneNumber ] = useState('');
    let [ email, setEmail ] = useState('');
    let [ contactAsap, setContactAsap ] = useState(false);
    let [ submitting, setSubmitting ] = useState(false);
    let [ submitted, setSubmitted ] = useState(false);
    let [ errorSubmitting, setErrorSubmitting ] = useState(false);
    let [ params, setParams ] = useSearchParams();

    const type = coerceType(params.get('type') ?? '');

    const clear = () => {
        setFirstName('');
        setLastName('');
        setPhoneNumber('');
        setEmail('');
        setContactAsap(false);
    };
    const reset = () => {
        clear();
        setSubmitting(false);
        setSubmitted(false);
        setErrorSubmitting(false);
    };

    const isDisabled = () => {

        if (!firstName || !lastName || !phoneNumber || !email) {
            return true;
        }

        return false;
    };

    let disabled = useMemo(() => isDisabled(), [ firstName, lastName, phoneNumber, email ]);

    const submit = () => {
        const body = { firstName, lastName, email, phoneNumber, contactAsap, type };
        setSubmitting(true);

        fetch('/submission', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then(() => {
            clear();
            setSubmitted(true);
            const confettiCannonCanvas = document.getElementById('confettiCannon');
            const confettiCannon = confetti.create(confettiCannonCanvas, {
                resize: true
            });
            confettiCannon({ particleCount: 100, spread: 100 });
            setSubmitting(false);
        }).catch(() => {
            setSubmitting(false);
            setErrorSubmitting(true);
        });
    };

    return (
        <div className="Info">
            <div className="card">
                { !submitting && !submitted && !errorSubmitting && <>
                    <div className="form">
                        <label htmlFor="firstName">First Name</label>
                        <input name="firstName" value={ firstName }
                               onChange={ event => setFirstName(event.target.value) }/>
                    </div>
                    <div className="form">
                        <label htmlFor="lastName">Last Name</label>
                        <input name="lastName" value={ lastName }
                               onChange={ event => setLastName(event.target.value) }/>
                    </div>
                    <div className="form">
                        <label htmlFor="phone">Phone Number</label>
                        <input type="text" name="phone" value={ phoneNumber }
                               onChange={ event => setPhoneNumber(event.target.value) }/>
                    </div>
                    <div className="form">
                        <label htmlFor="email">Email</label>
                        <input type="email" name="email" value={ email }
                               onChange={ event => setEmail(event.target.value) }/>
                    </div>
                    <div className="form checkbox-form">
                        <input type="checkbox" name="contactAsap" value={ `${ contactAsap }` }
                               onChange={ event => setContactAsap(event.target.checked) }/>
                        <label htmlFor="contactAsap">Contact Asap</label>
                    </div>

                    <button className="submit-button" disabled={ disabled } type="button" onClick={ submit }>Submit
                    </button>
                </> }
                { submitting && <div className="submitting">
                    <h1>Your form is being submitted</h1>
                </div> }
                { submitted && <div className="finished">
                    <h1>Form Complete!</h1>
                    <p>Thank you!</p>
                </div> }
                { errorSubmitting && <div className="error">
                    <h1>There was an issue submitting. Our apologies.</h1>
                    <button onClick={ reset }>Try Again</button>
                </div> }

                <div className="confetti">
                    <canvas id="confettiCannon"></canvas>
                </div>
            </div>
        </div>
    )
}
