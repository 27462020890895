import React from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import './styles/card.css';
import Info from './pages/info/info';
import LandingCar from './pages/landing-car/landing-car';
import LandingHome from './pages/landing-home/landing-home';
import Landing from './pages/landing/landing';

export default function App() {
  return (
      <div className="App">
        <Router>
          <Routes>
            <Route path="/landing" element={ <Landing/> }></Route>
            <Route path="/landing/home" element={ <LandingHome/> }></Route>
            <Route path="/landing/auto" element={ <LandingCar/> }></Route>
            <Route path="/info" element={ <Info/> }></Route>
            {/*<Route path="/finished" element={<Info/>}></Route>*/ }
            <Route path="/" element={ <Navigate to="/landing"/> }></Route>
          </Routes>
        </Router>
      </div>
  );
}
