import React from 'react';
import { Link } from 'react-router-dom';
import car from '../../assets/car.jpg';

export default function LandingCar() {
    return (
        <div className="landing-page" style={{ backgroundImage: `url(${car})` }}>
            <div className="prompt">
                <h1>Auto Insurance</h1>
                <p>Get an auto insurance quote from our agency and you may save thousands of dollars per year. It only takes a few minutes to find out how much money you can save!</p>
                <div>
                    <Link to="/info?type=auto">
                        <button>Get a Free Quote</button>
                    </Link>
                </div>
            </div>
            <a href="https://www.freepik.com/free-ai-image/person-taking-part-sustainable-travel-movement_94547304.htm#query=camping%20car&position=15&from_view=search&track=ais_ai_generated&uuid=7e310e76-5350-4448-ac01-84822fa409b4">Image
                By freepik</a>
        </div>
    )
}
